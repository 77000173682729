@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap');
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

body,
html {
    margin: 0;
}

* {
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    scroll-behavior: smooth;
    transition: 0.2s ease;
    letter-spacing: 1px;
    outline: none;
}

.feed__main {
    text-align: center;
    font-family: 'Manrope', sans-serif !important;
}

::selection {
    background-color: rgba(255, 255, 255, 0.4);;
}

::-webkit-scrollbar {
    width: 9px;
}

::-webkit-scrollbar-track {
    background-color: rgba(255, 204, 146, 0.533)
}

::-webkit-scrollbar-thumb {
    background-color: rgba(255, 184, 53, 0.5);
    border-radius: 3px;
}

a {
    text-decoration: none;
    color: inherit;
}

h1,
h2,
h3,
h4,
p {
    margin: 0;
}

body {
    margin: 0;
}

.pre__text {
    color: grey;
    margin: 10px;
}

.fl {
    display: -webkit-flex;
    display: flex;
}

.alc {
    align-items: center;
    -webkit-align-items: center;
}

.jcsa {
    justify-content: space-around;
}

.jcc {
    justify-content: center;
}

.top__block {
    width: 100%;
    color: white;
    will-change: transform;
    min-height: 100vh;
}

.top__block div {
    width: 50%;
    max-width: 100%;
    animation: fadeInLeft 1s ease;
}

.top__block_header {
    font-size: 4.5rem;
}

.s__block_home {
    width: 100%;
    background-color: rgb(255, 238, 217);
    padding: 25px;
}

.s__block_home h1 {
    text-align: center;
    font-size: 2.5rem;
}

.contacts_wi {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    width: 600px;
    max-width: 90%;
    cursor: pointer;
}

.contacts_wi div {
    display: flex;
    width: 460px;
    height: 70px;
    max-width: 95%;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    justify-content: start;
    border-radius: 5px;
    padding: 10px 15px;
    margin-block: 15px;
}

.contacts_wi div:hover {
    box-shadow: none;
}

.contacts_wi div img {
    width: 35px;
    margin-right: 10px;
    cursor: pointer;
}

.gallery_parent {
    width: 100%;
    background-color: rgb(255, 238, 217);
}

#top__block_pr {
    padding: 15px;
    border-radius: 10px;
    animation: fadeInToBottom 1s ease;
}

.effect__ {
    width: 100%;
    padding: 25px;
    height: 100%;
    backdrop-filter: blur(3px);
}

.wbg {
    background-color: rgba(0, 0, 0, 0.425);
}

.imasdi {
    margin: 0 auto;
    perspective: 700px;
    transform-style: preserve-3d;
}

.imasdi img {
    width: 80px;
    transform: rotateX(45deg);
    cursor: pointer;
    object-fit: cover;
    height: 80px;
    border-radius: 4px;
    margin: 5px;
    filter: contrast(0.4);
    max-width: 45%;
}

.imasdi img:hover {
    transform: rotateX(0);
    width: 250px;
    height: 250px;
    filter: contrast(1);
}

.gallery_mini_imgs {
    width: 300px;
    display: flex;
    flex-wrap: wrap;
}

.gallery_mini_imgs img {
    width: 100px;
    object-fit: cover;
    height: 100px;
    border-radius: 4px;
    margin: 5px;
    max-width: 45%;
}

.target__bg_gl {
    width: 400px;
    height: 400px;
    background-position: center;
    background-size: cover;
    border-radius: 8px;
    max-width: 95%;
    max-height: 95%;
}

.onclick_more_info {
    cursor: pointer;
}

.onclick_more_info:focus .bdb {
    backdrop-filter: blur(3px);
}

.bdr_true {
    backdrop-filter: blur(3px);
}

.active__textabout {
    animation: fadeInToBottom 1s ease forwards;
    cursor: pointer;
}

.moreinfo_read {
    text-align: justify;
    opacity: 0.0;
}

.nonactive_textabout {
    animation: fadeOutToBottom 1s ease forwards;
}

.slider {
    max-width: 100%;
    position: relative;
    margin: 0 auto;
    width: 320px;
    height: 320px;
}

.slider .item img {
    object-fit: cover;
    width: 320px;
    height: 320px;
    max-width: 100%;
    border-radius: 5px;
}

.slider .item_1 img {
    object-fit: cover;
    width: 320px;
    height: 320px;
    max-width: 100%;
    border-radius: 5px;
}

.slider .item_2 img {
    object-fit: cover;
    width: 320px;
    height: 320px;
    max-width: 100%;
    border-radius: 5px;
}

.slider .previous,
.next {
    background-color: rgba(228, 22, 84, 0.282);
}

.slider .previous_,
.next_ {
    background-color: rgba(114, 114, 114, 0.282);
}

.slider .previous {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 3px;
}

.slider .next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    border-radius: 3px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    left: 86.4%
}

.slider .previous_ {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 3px;
    right: 96%;
}

.slider .next_ {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    border-radius: 3px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    left: 96%
}

.btn-disabled {
    background-color: rgba(255, 255, 255, 0.533);
    color: rgb(119, 119, 119);
}

.btn-disabled:hover {
    background-color: rgba(255, 255, 255, 0.533);
    color: rgb(119, 119, 119);
}

.slider .previous:hover,
.slider .next:hover {
    background-color: rgba(228, 22, 84, 0.582);
}

.slider .item {
    animation-name: fade;
    animation-duration: 1.5s;
}

.item {
    width: 320px;
    max-width: 100%;
}

.slider .item_1 {
    animation-name: fade;
    animation-duration: 1.5s;
}

.item_1 {
    width: 320px;
    max-width: 100%;
}

.slider .item_2 {
    animation-name: fade;
    animation-duration: 1.5s;
}

.item_2 {
    width: 320px;
    max-width: 100%;
}

.feedback_block {
    width: 500px;
    max-width: 95%;
    background-color: rgba(255, 238, 217, 0.5);
    backdrop-filter: blur(10px);
    margin: 55px auto 50px auto;
    box-shadow: rgba(0, 0, 0, 0.3) 0 4px 12px;
    padding: 15px;
    border-radius: 10px;
}

.comments_main {
    background-color: rgb(255, 238, 217);
    width: 100%;
    min-height: 400px;
    padding: 25px;
}

textarea {
    resize: none;
}

input,
textarea {
    width: 250px;
    max-width: 95%;
    border: 2px solid rgb(229, 180, 0);
    padding: 12px;
    border-radius: 7px;
    font-size: 0.75rem;
    margin-bottom: 15px;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    max-width: 95%;
    border: 2px solid rgb(229, 180, 0);
    padding: 12px;
    border-radius: 7px;
    font-size: 0.75rem;
    margin-bottom: 15px;
}

button {
    width: 150px;
    max-width: 95%;
    background-color: rgb(255,198,56);
    padding: 12px;
    border-radius: 7px;
    margin-bottom: 15px;
    border: unset;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

button:hover {
    background-color: rgba(229, 180, 0, 0.8);
}

.form textarea {
    width: 350px;
    max-width: 95%;
    resize: none;
    border: 2px solid rgb(229, 180, 0);
    padding: 7px 12px;
    border-radius: 7px;
    margin-bottom: 15px;
}

.prefer_auth {
    position: fixed;
    right: 10px;
    top: 10px;
    z-index: 100;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.prefer_auth button {
    margin-bottom: 0;
    margin-left: 10px;
}

.comment {
    box-shadow: rgba(0, 0, 0, 0.3) 0 4px 12px;
    padding: 10px;
    border-radius: 5px;
    background-color: rgb(72, 72, 72);
    color: white;
    width: 250px;
    margin-inline: 10px;
    margin: 5px;
}

.auth__main {
    width: 100%;
    height: 100vh;
    /*background-image: url('./lib/other/main.jpg');*/
    /*background-size: cover;*/
    /*background-position: center;*/
    /*background-repeat: no-repeat;*/
    background-image: url('./lib/other/bgmain.png');
    background-size: contain;
    background-position: center;
    background-repeat: repeat-y;
    background-color: rgb(255, 255, 255);
    position: fixed;
}

.auth-box {
    background-color: rgba(221, 221, 221, 0.5);
    width: 500px;
    max-width: 95%;
    border-radius: 10px;
    padding: 25px;
    margin: 250px auto 0 auto;
    backdrop-filter: blur(10px);
    text-align: center;
}

.auth-box div img {
    width: 150px;
    height: 100px;
}

.auth-box div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.about_parent {
    background-image: url('./lib/other/main.jpg');
    width: 100%;
    min-height: 100vh;
    padding-top: 100px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

}

.about_mid {
    color: white;
    width: 500px;
    max-width: 90%;
    text-align: justify;
    left: 0;
    right: 0;
}

.about_mid h1 {
    font-size: 2.0rem;
    text-align: center;
    font-weight: 600;
}

.about_mid h2 {
    font-size: 1.5rem;
    text-align: center;
    font-weight: 600;
}

.about_mid p {
    letter-spacing: 2px;

}

.App-homescreen {
    width: 100%;
    min-height: 100vh;
    background-image: url('./lib/other/bgmain.png');
    background-size: contain;
    background-position: center;
    background-repeat: repeat-y;
    padding-bottom: 100px;
    background-color: #fff;
}

.first-screen {
    width: 1200px;
    max-width: 100%;
    margin: 0 auto;
    padding-block: 20px;
}

.btn-goauth {
    background-color: #e41655;
    color: white;
    min-width: 100px;
    width: auto;
    margin: 10px 0 0 10px;
    -webkit-box-shadow: 0 10px 24px 1px rgba(228, 22, 85, 0.3);
    -moz-box-shadow: 0 10px 24px 1px rgba(228, 22, 85, 0.3);
    box-shadow: 0 10px 24px 1px rgba(228, 22, 85, 0.3);
}

.btn-goauth:hover {
    background-color: rgba(228, 22, 84, 0.851);
    transform: translateY(-3px) translateX(3px);
}

.pink-text {
    color: #e41655;
    text-shadow: 0 10px 24px rgba(228, 22, 85, 0.3);
}

.xxl-text {
    font-size: 2.0rem;
}

.xl-text {
    font-size: 1.5rem;
}

.first-screen-info {
    max-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    padding-top: 25px;
}

.first-screen-info article {
    margin: 15px;
}

.img-square-cutted {
    width: 350px;
    height: 350px;
    object-fit: cover;
    border-radius: 25px;
}

.ss-parent {
    width: 100%;
    height: 100px;
    padding-top: 50px;
}

.second-screen {
    width: 600px;
    max-width: 90%;
    margin: 0 auto;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

}

.island {
    background-color: #e41655;
    border-radius: 10px;
    padding: 15px;
    width: 400px;
    max-width: 90%;
    z-index: 10;
    -webkit-box-shadow: 0 10px 24px 1px rgba(228, 22, 85, 0.3);
    -moz-box-shadow: 0 10px 24px 1px rgba(228, 22, 85, 0.3);
    box-shadow: 0 10px 24px 1px rgba(228, 22, 85, 0.3);
    color: white;
    margin-block: 25px;
}

.third-screen {
    width: 1000px;
    max-width: 100%;
    margin: 0 auto;

}

.signup-firstclass {
    width: 100%;
}

.form-parent {
    width: 500px;
    max-width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
}

.form {
    width: 450px;
    max-width: 90%;
    background-color: #e41655;
    color: white;
    padding: 15px;
    border-radius: 10px;
    z-index: 10;
    -webkit-box-shadow: 0 10px 24px 1px rgba(228, 22, 85, 0.3);
    -moz-box-shadow: 0 10px 24px 1px rgba(228, 22, 85, 0.3);
    box-shadow: 0 10px 24px 1px rgba(228, 22, 85, 0.3);
    margin-bottom: 50px;
}

.main-input {
    border-radius: 25px;
    border: unset;
    -webkit-box-shadow: 0 0 10px 2px rgba(34, 60, 80, 0.2) inset;
    -moz-box-shadow: 0 0 10px 2px rgba(34, 60, 80, 0.2) inset;
    box-shadow: 0 0 10px 2px rgba(34, 60, 80, 0.2) inset;
    max-width: 90%;
}

.signup-btn {
    background-color: #ffffff;
    border-radius: 25px;
    border: unset;
    max-width: 90%;
}

.signup-btn:hover {
    color: white;
}

.about-main {
    padding-top: 100px;
}

.social-href {
    max-width: 90%;
    width: 340px;
    background-color: #e41655;
    color: white;
    padding: 10px 12px;
    border-radius: 10px;
    z-index: 10;
    -webkit-box-shadow: 0 10px 24px 1px rgba(228, 22, 85, 0.3);
    -moz-box-shadow: 0 10px 24px 1px rgba(228, 22, 85, 0.3);
    box-shadow: 0 10px 24px 1px rgba(228, 22, 85, 0.3);
    margin-block: 10px;
}

.ac-main {
    width: 850px;
    max-width: 100%;
    margin: 50px auto 0 auto;
}

.button-save-profile {
    position: fixed;
    top: 10px;
    right: 10px;
    width: auto;
}
@keyframes fade {
    from {
        opacity: 0.6
    }

    to {
        opacity: 1
    }
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-2px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes fadeInToBottom {
    0% {
        opacity: 0.0;
        transform: translateY(-250px);
    }

    70% {
        opacity: 0.2;
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
        display: block;
    }
}

@keyframes fadeOutToBottom {
    0% {
        opacity: 1;
        transform: translateX(0px);
        display: block;
    }

    100% {
        opacity: 0.0;
        transform: translateY(-250px);
        display: none;
    }
}

@keyframes fadeInLeft {
    0% {
        opacity: 0.5;
        transform: translateX(-30px);
    }

    100% {
        opacity: 1;
        transform: translateX(0px);
        display: block;
    }
}

@media screen and(max-width: 830px) {
    .gallery_col {
        flex-direction: column-reverse !important;
    }
}

@media screen and (max-width: 500px) {
    .xxl-text {
        font-size: 1.7rem;
    }
    button {
        padding: 7px 12px !important;
    }
    .xl-text {
        font-size: 1.3rem;
    }
}

@media screen and (max-width: 658px) {
    #kj21n4 {
        flex-direction: column-reverse;
    }
}