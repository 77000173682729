body {
    background-image: url('../lib/other/wave.svg');
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    margin: 0;
}

* {
    box-sizing: border-box;
}

.input {
    width: 350px;
    max-width: 95%;
    border: 2px solid rgb(229, 180, 0);
    padding: 12px;
    border-radius: 7px;
    margin-bottom: 15px;
}

.leftSide__menu {
    background-color: white;
    width: 220px;
    padding-inline: 20px;
    position: fixed;
    top: 50px;
    left: 50px;
    font-size: 24px;
    list-style: none;
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 4px 12px;
}

ul {
    list-style: none;
    padding: 0;
}

.loader_page {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loader {
    animation: spin 1s linear infinite;
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border-left: 3px solid blue;
}

.create_pupil_block {
    max-width: 100%;
}

.create_pupil_block article {
    background-color: rgba(255, 187, 187, 0.2);
    backdrop-filter: blur(3px);
    border-radius: 10px;
}

.main__content {
    margin: 50px auto 0 auto;
    width: 1100px;
    max-width: 95%;
    padding: 10px;
}

.bg__ {
    width: 100%;
    height: 100%;
    background-image: url('../lib/other/main.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-color: rgb(241, 251, 255);
    padding-bottom: 25px;
}

.mainp_a {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.3) 0 4px 12px;
    border-radius: 15px;
    padding: 12px;
    max-width: 95%;
    margin-bottom: 25px;
}

.diary_admin_daily {
    display: flex;
    margin: 0 auto;
}

.diary_admin_daily div {
    border-right: 1px solid grey;
    width: 100px;
    height: 100px;
    margin-bottom: 0;
    border-radius: unset;
    font-size: 14px;
    box-shadow: none;
}

.pupils_list {
    width: 900px;
    max-width: 95%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto 50px auto;
}

.leftSide_menu_hide {
    height: 100vh;
    position: fixed;
    left: -300px;
    top: 0;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.3) 0 4px 12px;
    width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-block: 100px;
}

.leftside_active__ {
    left: 0;
}

.leftSide_activator {
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 100;

}

.leftSide__menu ul li a p {
    color: black;
    font-weight: 500;
}

.leftSide__menu ul li {
    margin-bottom: 5px;
}

.create_pupil_block article {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pupil_card {
    background-color: white;
    border-radius: 10px;
    padding: 15px;
    width: 430px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 4px 12px;
    margin-inline: 10px;
}

#j23n4 p {
    margin-block: 5px;
}

.profile_card_top {
    width: 500px;
    box-shadow: rgba(0, 0, 0, 0.3) 0 4px 12px;
    border-radius: 10px;
    background-color: white;
    margin: 75px auto;
    padding: 15px;
    max-width: 95%;
}

.calendar__ {
    background-color: white;
    width: 900px;
    max-width: 95%;
    box-shadow: rgba(0, 0, 0, 0.3) 0 4px 12px;
    border-radius: 10px;
    padding: 15px;
    margin: 50px auto;
}

.day__calen {
    box-shadow: rgba(0, 0, 0, 0.3) 0 4px 12px;
    border-radius: 5px;
    padding: 15px;
    padding-inline: 30px;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.day__calen_c {
    box-shadow: rgba(0, 0, 0, 0.3) 0 4px 12px;
    border-radius: 5px;
    padding: 15px;
    padding-inline: 30px;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.an__section {
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.3) 0 4px 12px;
    width: 500px;
    max-width: 95%;
    border-radius: 10px;
    margin: 50px auto;
    padding: 20px;
}

.c__cd {
    display: flex;
    align-items: center;
    background-color: rgb(255,247,219);
    box-shadow: rgba(0, 0, 0, 0.3) 0 4px 12px;
    padding: 10px;
    border-radius: 10px;
    margin: 10px;
    justify-content: space-evenly;
    flex-wrap: wrap;
}

.dc_item {
    padding: 5px;
    margin: 5px;
    border-bottom: 1px solid lightgray;
}

.dc_day {
    text-align: center !important;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.active__text {
    font-weight: 500;
}

@media screen and (max-width: 1700px) {
    .leftSide__menu {
        display: none;
    }

    .leftSide_activator {
        display: block;
    }
}

@media screen and (min-width: 1700px) {
    .leftSide__menu {
        display: block;
    }

    .leftSide_activator {
        display: none;
    }
}

@media screen and (max-width: 660px) {
    .dc_day {
        border-right: unset !important;
    }

    .dc_item div {
        justify-content: space-between !important;
    }

    .day__calen div {
        width: 95% !important;
    }
}

@media screen and (max-width: 720px) {
    .dc_item {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 600px) {
    .day__calen_c {
        flex-direction: column;
        align-items: center;
        padding: 10px 15px;
    }

    h4 {
        font-size: 0.8rem;
    }

    h3 {
        font-size: 1.0rem;
    }
}

@media screen and (max-width: 500px) {
    .calendar__ h1 {
        font-size: 1.5rem;
    }

    .day__calen {
        padding: 10px 0 0 0;
    }

    .dc_day {
        margin-bottom: 10px;
    }

    .nmj2k {
        font-size: 0.9rem !important;
    }

    .k3m12 {
        justify-content: center !important;
    }
}